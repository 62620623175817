import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React, { Component } from "react"
import Helmet from "react-helmet"
import Slider from "react-slick"
import Breadcrumb from "../components/common/breadcrumb/breadcrumb"
import ContentWrap from "../components/common/content-wrap/content-wrap"
import Layout from "../components/layout/Layout"

const ProjectSinglePage = ({ data }) => {
  const project = data.allContentfulMahpsaProyecto.edges[0].node
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  }
  const description = renderRichText(project.sobreProyecto)
  console.log("images")
  console.log(project.galeria)

  return (
    <Layout>
      <div className={`apartments-page-wrap`}>
        <Breadcrumb
          title={project.slug}
          parent={[{ path: "proyecto", title: "Apartments" }]}
        />
        <Helmet>
          <title>Mahpsa | {project.slug}</title>
        </Helmet>
        <ContentWrap isBoxed={true}>
          <div className="entry-image text-center">
            {project.galeria && project.galeria.length > 0 ? (
              <Slider {...settings}>
                {/* <div> */}
                {project.galeria.map((image, index) => (
                  <div className="slider-item" key={index}>
                    <GatsbyImage
                      className="img-apartment"
                      image={getImage(image)}
                      alt={"proyecto imagen"}
                    />
                  </div>
                ))}
                {/* <div className="slider-item" key={'1'}>
                  <GatsbyImage
                    className="img-apartment"
                    image={getImage(project.galeria[0])}
                    alt={"proyecto imagen"}
                  />
                </div> */}
                {/* </div> */}
              </Slider>
            ) : (
              <h3>No gallery</h3>
            )}
          </div>
          <div className="entry-meta">
            <span className="post-date">{project.estatus}</span>
          </div>
          <div
            className="entry-content"
            //   dangerouslySetInnerHTML={{ __html: project.sobreProyecto.raw }}
          >
            {description}
          </div>
        </ContentWrap>
      </div>
    </Layout>
  )
}

export default ProjectSinglePage

export const query = graphql`
  query ProjectDetails($slug: String) {
    allContentfulMahpsaProyecto(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          nombre
          calle
          distrito
          estatus
          imagenFondo {
            gatsbyImageData
          }
          imagenPrincipal {
            gatsbyImageData
          }
          link
          lema
          logo {
            gatsbyImageData
          }
          slug
          sobreProyecto {
            raw
          }
          galeria {
            gatsbyImageData
          }
        }
      }
    }
  }
`
